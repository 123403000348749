@media screen {
	.devBeamInside {
		@apply tw-m-auto tw-flex tw-flex-col md:tw-flex-row md:tw-gap-3 md:tw-items-center tw-relative tw-bg-white;
		max-width: 1200px;
		z-index: var(--devBeam-zi);
	}
	.dev-beam-section-title {
		@apply tw-uppercase tw-text-navy tw-border-b tw-border-solid tw-border-navy tw-flex tw-justify-between tw-items-center tw-w-full;
		margin: 0 0 10px;
		padding: 0 0 5px;
	}

	.dev-beam-section-title-icon {
		@apply tw-opacity-50;
		width: 15px;
		line-height: 0;
		margin: 0 0 0 15px;
		img {
			@apply tw-w-full tw-h-full;
		}
	}
}
